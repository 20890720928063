<!DOCTYPE html>
<html lang="en">

<body class="min-vh-100 d-flex flex-column cover" style="
position: fixed;
opacity: 1;
background-color: rgba(0,0,0,.6);
/* left: 0px; */
/* top: 0px; */
width: 100%;
height: 100%;
z-index: -1;
">

  <div class="cover black" data-color="black" style="
  position: fixed;
  opacity: 1;
  background-color: rgba(0,0,0,.6);
  /* left: 0px; */
  /* top: 0px; */
  width: 100%;
  height: 100%;
  z-index: -1;
"></div>

    <header>
        <div class="container">
            <nav class="navbar navbar-dark bg-transparenet">
                <a class="navbar-brand" href="#">
                    <img src="https://res.cloudinary.com/kaushalshah1307/image/upload/v1628489306/whitelilyevent/whitelilyevent_logo_white.png" alt="logo">
                </a>
                <span class="navbar-text ml-auto d-sm-inline-block">922.740.9022</span>
                <span class="navbar-text d-sm-inline-block">info@whitelilyevent.com</span>
            </nav>
        </div>
    </header>
    <div class="my-auto">
        <div class="container">
            <h1 class="page-title">WHITE LILY EVENTS</h1>
            <h3 class="coming-soon" style="color: goldenrod; font-weight: 500;">
                OUR REVAMPED WEBSITE IS COMING SOON!
            </h3>
            <p></p>
            <p></p>
            <p class="page-description">White Lily Events &amp; Consultancy presents you a fresh and creative way of celebrating your special occasions and planning events.</p>
            <p>Stay connected</p>
            <nav class="footer-social-links">
                <a target="_blank" href="https://www.facebook.com/whitelilyeventsandconsultancy/" class="social-link">
                    <i class="mdi mdi-facebook-box"></i>
                </a>
                <a target="_blank" href="https://www.instagram.com/whitelilyevents/" class="social-link">
                    <i class="mdi mdi-instagram"></i>
                </a>
                <a href="#!" class="social-link">
                    <i class="mdi mdi-google"></i>
                </a>
            </nav>
            <p class="copyright" style="margin-top: 20px;">&copy; 2021 White Lily Events. All rights reserved.</p>
        </div>

        <app-footer></app-footer>
    </div>

</body>

</html>